import type { GlobalState, TabState } from './types';
import { ChangeGroupUserPermissionsType, LeftColumnContent, NewChatMembersProgress } from '../types';

import {
  ANIMATION_LEVEL_DEFAULT,
  DARK_THEME_PATTERN_COLOR,
  DEFAULT_MESSAGE_TEXT_SIZE_PX,
  DEFAULT_PATTERN_COLOR,
  DEFAULT_PLAYBACK_RATE,
  DEFAULT_VOLUME,
  IOS_DEFAULT_MESSAGE_TEXT_SIZE_PX,
  MACOS_DEFAULT_MESSAGE_TEXT_SIZE_PX,
} from '../config';
import { IS_IOS, IS_MAC_OS } from '../util/environment';
import { LiveRole } from '../components/live/types';

// 初始化全局状态值
export const INITIAL_STATE: GlobalState = {
  authState: 'authorizationStateUserNameLogin',
  isLoadingData: false,
  isLeftColumnShown: true,
  leftColumnContentType: LeftColumnContent.ChatList,
  isChatInfoShown: false,
  newChatMembersProgress: NewChatMembersProgress.Closed,
  uiReadyState: 0,
  serverTimeOffset: 0,
  isUpdateAvailable: false,
  isOutermostSidebar: false, // 是否是最左侧的侧边栏
  isDiscoverSelect: false, // 是否显示发现模块
  isCustomerSelect: false, // 我的客户模块是否展示
  token: '', // 用户token
  inviteCode: '', // 用户邀请码
  recommendCode: '', // 用户推荐码
  forceInviteCode: '', // 登录机制
  isServicer: 0, // 用户是否是客服
  temporaryInviteCode: '', // 用户填写临时邀请码
  temporaryRecommendCode: '', // 用户填写临时推荐码
  currentUserId: '', // 当前用户ID
  hasInviteCode: false, // 是否支持邀请码
  isCanReport: true, // 是否可以举报
  needUploadChatContent: false, // 是否需要上传聊天记录
  showOnlineTime: true, // 是否显示当前用户是否在线，以及最后在线时间
  browserIDVal: false, // broswerID 的值，没有的话，默认false
  isClientStartup: true, // 是否是客户端启动
  selectChatMemberId: '',
  changeGroupUserPermissionsType: ChangeGroupUserPermissionsType.Closed,

  isWaitingNumber: 0, // 语音等待触发次数
  isComing: false,
  regIpLimit: 0, // 限制IP注册数量 > 0的时候, 同一IP只能注册3个账号。
  showActionMsg: true, // 是否显示群内系统消息




  // User permissions
  // canChatWithOthers: false, // 是否可以和其他人聊天
  // canLookOverOthers: false, // 是否可以查看其他人信息(对应权限2)
  canSearch: true, // 是否可以查询(对应权限3)
  canShowAllChannelsUsers: false, // 是否需要过滤聊天列表(对应权限4)

  canShowContacts: true, // 可直接对他人发起会话、视频、语音、正常查看用户名、二维码，等信息与功能(对应权限1)

  canBlock: true, // 是否屏蔽其他人
  canDeleteHistory: true, // 是否可以删除聊天记录
  canDeleteOther: true, // 是否可以同时删除他人和自己的聊天记录
  needShowContacts: true, // 是否显示联系人
  needShowPhone: true, // 正常查看和访问他人手机号码
  subShowContactsValue: 3, // 是否显示联系人（子功能）
  isBlockadeAccount: false, // 踢人/用户已经被禁止登录
  isWriteInviteCodeModalOpen: false, // 是否显示填写邀请码弹窗
  isWriteRecommendCodeModalOpen: false, // 是否显示填写推荐码

  showPhoneSign: false, // 是否显示手机号登录和注册

  isLiveBegin: false, // live
  liveRole: LiveRole.viewers,
  authKey: '',
  mainTab: 'chat',
  liveHide: false,

  leftBanner: [],

  newChatId: '', // 新会话ID
  newWhiteList: [], // 新的白名单
  discoveryUrl: '', // 发现链接
  customerList: [], // 客户列表
  customerPageNum: 1, // 客户列表的页码
  customerLastPageNum: 1, // 客户列表的最后页面
  customerTotal: 0, // 统计客户列表的总人数
  onlineCustomerServiceList: [],

  authRememberMe: true,
  countryList: {
    phoneCodes: [],
    general: [],
  },

  blocked: {
    ids: [],
    totalCount: 0,
  },

  users: {
    byId: {},
    statusesById: {},
  },

  chats: {
    listIds: {},
    isFullyLoaded: {},
    orderedPinnedIds: {},
    totalCount: {},
    byId: {},
  },

  messages: {
    byChatId: {},
    messageLists: [],
    sponsoredByChatId: {},
  },

  groupCalls: {
    byId: {},
  },

  scheduledMessages: {
    byChatId: {},
  },

  chatFolders: {
    byId: {},
    orderedIds: [0],
    activeChatFolder: 0,
  },

  fileUploads: {
    byMessageLocalId: {},
  },

  recentEmojis: ['grinning', 'kissing_heart', 'christmas_tree', 'brain', 'trophy', 'duck', 'cherries'],
  recentCustomEmojis: ['5377305978079288312'],

  stickers: {
    setsById: {},
    added: {},
    recent: {
      stickers: [],
    },
    favorite: {
      stickers: [],
    },
    greeting: {
      stickers: [],
    },
    premium: {
      stickers: [],
    },
    premiumSet: {
      stickers: [],
    },
    featured: {
      setIds: [],
    },
    search: {},
    forEmoji: {},
  },

  customEmojis: {
    lastRendered: [],
    byId: {},
    added: {},
    forEmoji: {},
  },

  emojiKeywords: {},

  gifs: {
    saved: {},
    search: {},
  },

  inlineBots: {
    isLoading: false,
    byUsername: {},
  },

  globalSearch: {},

  userSearch: {},

  localTextSearch: {
    byChatThreadKey: {},
  },

  localMediaSearch: {
    byChatId: {},
  },

  management: {
    byChatId: {},
  },

  topPeers: {},

  topInlineBots: {},

  mediaViewer: {
    volume: DEFAULT_VOLUME,
    playbackRate: DEFAULT_PLAYBACK_RATE,
    isMuted: false,
  },

  audioPlayer: {
    volume: DEFAULT_VOLUME,
    playbackRate: DEFAULT_PLAYBACK_RATE,
    isMuted: false,
  },

  forwardMessages: {},
  forwardMessagesToFolder: {},
  forwardMessagesToAll: {},
  pollResults: {},

  payment: {},

  notifications: [],

  dialogs: [],

  activeSessions: {
    byHash: {},
    orderedHashes: [],
  },

  activeWebSessions: {
    byHash: {},
    orderedHashes: [],
  },
  discoverList: [],
  settings: {
    byKey: {
      theme: window.location.href.includes('dark') ? 'dark' : 'light',
      shouldUseSystemTheme: false,
      messageTextSize: IS_IOS
        ? IOS_DEFAULT_MESSAGE_TEXT_SIZE_PX
        : (IS_MAC_OS ? MACOS_DEFAULT_MESSAGE_TEXT_SIZE_PX : DEFAULT_MESSAGE_TEXT_SIZE_PX),
      animationLevel: ANIMATION_LEVEL_DEFAULT,
      messageSendKeyCombo: 'enter',
      canAutoLoadPhotoFromContacts: true,
      canAutoLoadPhotoInPrivateChats: true,
      canAutoLoadPhotoInGroups: true,
      canAutoLoadPhotoInChannels: true,
      canAutoLoadVideoFromContacts: true,
      canAutoLoadVideoInPrivateChats: true,
      canAutoLoadVideoInGroups: true,
      canAutoLoadVideoInChannels: true,
      canAutoLoadFileFromContacts: false,
      canAutoLoadFileInPrivateChats: false,
      canAutoLoadFileInGroups: false,
      canAutoLoadFileInChannels: false,
      autoLoadFileMaxSizeMb: 10,
      hasWebNotifications: true,
      hasPushNotifications: true,
      notificationSoundVolume: 5,
      canAutoPlayGifs: true,
      canAutoPlayVideos: false,
      shouldSuggestStickers: true,
      shouldSuggestCustomEmoji: true,
      shouldLoopStickers: true,
      language: 'classic-zh-cn', // classic-zh-cn
      timeFormat: '24h',
      wasTimeFormatSetManually: false,
      isConnectionStatusMinimized: true,
      shouldArchiveAndMuteNewNonContact: false,
    },
    themes: {
      light: {
        isBlurred: true,
        patternColor: DEFAULT_PATTERN_COLOR,
      },
      dark: {
        isBlurred: true,
        patternColor: DARK_THEME_PATTERN_COLOR,
      },
    },

    privacy: {
      phoneNumber: {
        visibility: 'contacts',
        allowUserIds: [],
        allowChatIds: [],
        blockUserIds: [],
        blockChatIds: [],
      },
      lastSeen: {
        visibility: 'contacts',
        allowUserIds: [],
        allowChatIds: [],
        blockUserIds: [],
        blockChatIds: [],
      },
      profilePhoto: {
        visibility: 'contacts',
        allowUserIds: [],
        allowChatIds: [],
        blockUserIds: [],
        blockChatIds: [],
      },
      forwards: {
        visibility: 'contacts',
        allowUserIds: [],
        allowChatIds: [],
        blockUserIds: [],
        blockChatIds: [],
      },
      voiceMessages: {
        visibility: 'contacts',
        allowUserIds: [],
        allowChatIds: [],
        blockUserIds: [],
        blockChatIds: [],
      },
      chatInvite: {
        visibility: 'contacts',
        allowUserIds: [],
        allowChatIds: [],
        blockUserIds: [],
        blockChatIds: [],
      },
      phoneCall: {
        visibility: 'contacts',
        allowUserIds: [],
        allowChatIds: [],
        blockUserIds: [],
        blockChatIds: [],
      },
      phoneP2P: {
        visibility: 'contacts',
        allowUserIds: [],
        allowChatIds: [],
        blockUserIds: [],
        blockChatIds: [],
      },
    },

    notifyExceptions: {},
  },

  twoFaSettings: {},
  passcode: {},
  activeReactions: {},

  shouldShowContextMenuHint: true,

  activeDownloads: {
    byChatId: {},
  },

  serviceNotifications: [],

  statistics: {
    byChatId: {},
  },

  pollModal: {
    isOpen: false,
  },

  trustedBotIds: [],

  attachMenu: {
    bots: {},
  },

  transcriptions: {},
  byTabId: {},
  userRights: {
    showMember: false,
    createChannel: false,
    createGroup: false,
    addContact: false,
  },
  setCoinInfo: [],

  // packet
  isVisible: false,
  setIsEnvelopeVisible: false,
  claimRedEnvelopeInfo: {},
  changedLive: {
    id: '',
    type: false
  },
  archiveSettings: {
    isMinimized: false,
    isHidden: false
  },
  setEnvelope: undefined,
  merchantInfo: {
    havaCall: false,
    haveRedEnvelopes: false,
    haveGuest: false,
    link: '',
    liveroomLink: '',
    merchantName: '',
  },
  chatMember: undefined,
};
export const INITIAL_TAB_STATE: TabState = {
  id: 0,
  isMasterTab: false,
  isLeftColumnShown: true,
  isChatInfoShown: false,
  newChatMembersProgress: NewChatMembersProgress.Closed,
  uiReadyState: 0,
  shouldInit: true,

  gifSearch: {},
  stickerSearch: {},

  messageLists: [],
  activeChatFolder: 0,
  tabThreads: {},

  inlineBots: {
    isLoading: false,
    byUsername: {},
  },

  globalSearch: {},

  userSearch: {},

  localTextSearch: {
    byChatThreadKey: {},
  },

  localMediaSearch: {
    byChatThreadKey: {},
  },

  management: {
    byChatId: {},
  },

  mediaViewer: {
    volume: DEFAULT_VOLUME,
    playbackRate: DEFAULT_PLAYBACK_RATE,
    isMuted: false,
  },

  audioPlayer: {
    volume: DEFAULT_VOLUME,
    playbackRate: DEFAULT_PLAYBACK_RATE,
    isMuted: false,
  },

  forwardMessages: {},

  pollResults: {},

  payment: {},

  notifications: [],

  dialogs: [],

  activeReactions: {},

  activeDownloads: {
    byChatId: {},
  },

  statistics: {
    byChatId: {},
  },

  pollModal: {
    isOpen: false,
  },

  requestedTranslations: {
    byChatId: {},
  },
};
